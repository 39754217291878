<template lang="pug">
    .editor-toolbar(v-if="editor" :class="{focused: editor.name === focusedEditor.name}")
        v-row
            v-col.buttons
                v-btn(icon, @click="editor.chain().undo().focus().run()" :disabled="!editor.can().undo()" v-if="documentEditor")
                    v-icon mdi-undo
                v-btn(icon, @click="editor.chain().redo().focus().run()" :disabled="!editor.can().redo()" v-if="documentEditor")
                    v-icon mdi-redo
                .delimiter(v-if="documentEditor")
                v-menu(offset-y v-if="documentEditor")
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.dropdown(v-bind="attrs", v-on="on" title="Element type" plain :ripple="false")
                            v-icon(v-if="editor.isActive('heading', { level: 1 })") mdi-format-header-1
                            v-icon(v-if="editor.isActive('heading', { level: 2 })") mdi-format-header-2
                            v-icon(v-if="editor.isActive('heading', { level: 3 })") mdi-format-header-3
                            v-icon(v-if="editor.isActive('heading', { level: 4 })") mdi-format-header-4
                            v-icon(v-if="editor.isActive('heading', { level: 5 })") mdi-format-header-5
                            v-icon(v-if="editor.isActive('heading', { level: 6 })") mdi-format-header-6
                            v-icon(v-if="editor.isActive('paragraph')") mdi-format-paragraph
                            v-icon(v-if="multiFormat") mdi-group
                            span.text Type
                            v-icon.dropdown-icon mdi-menu-down
                    v-list
                        v-list-item(v-for="(tag, index) in tags", :key="index" :class="{active: tag.active}" @click="tag.action")
                            v-list-item-icon
                                v-icon(v-text="tag.icon")
                            v-list-item-title {{ tag.name }}

                v-menu.align-menu(offset-y :close-on-content-click="false" :nudge-width="200" content-class="align-menu" @input="setSelectedColor" v-model="colorDropdown" v-if="documentEditor")
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.dropdown( v-bind="attrs" v-on="on" title="Text color" plain :ripple="false")
                            v-icon mdi-palette
                            span.text Color
                            v-icon.dropdown-icon mdi-menu-down
                    v-card.color-card
                        v-color-picker(show-swatches hide-inputs hide-canvas hide-sliders swatches-max-height="230px" @input="setTextColor" v-model="color")
                        v-btn.reset-color(light @click="resetColor" :disabled="!selectedColor")
                            v-icon mdi-invert-colors-off
                            span Reset text color

                v-menu.align-menu(offset-y v-if="documentEditor")
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.dropdown( v-bind="attrs" v-on="on" title="Align text" plain :ripple="false")
                            v-icon(v-if="editor.isActive({ textAlign: 'left' })") mdi-format-align-left
                            v-icon(v-if="editor.isActive({ textAlign: 'center' })") mdi-format-align-center
                            v-icon(v-if="editor.isActive({ textAlign: 'right' })") mdi-format-align-right
                            v-icon(v-if="editor.isActive({ textAlign: 'justify' })") mdi-format-align-justify
                            v-icon(v-if="notAligned") mdi-format-align-left
                            span.text Align text
                            v-icon.dropdown-icon mdi-menu-down
                    v-list
                        v-list-item(v-for="(format, index) in formats", :key="index" :class="{active: format.active}" @click="format.action")
                            v-list-item-icon
                                v-icon(v-text="format.icon")
                            v-list-item-title {{ format.name }}

                .delimiter(v-if="documentEditor")
                v-btn(icon @click="editor.chain().toggleBold().focus().run()" :class="{ 'active': editor.isActive('bold') }")
                    v-icon mdi-format-bold
                v-btn(icon @click="editor.chain().toggleItalic().focus().run()" :class="{ 'active': editor.isActive('italic') }")
                    v-icon mdi-format-italic
                v-btn(icon @click="editor.chain().toggleStrike().focus().run()" :class="{ 'active': editor.isActive('strike') }")
                    v-icon mdi-format-strikethrough-variant


                template(v-if="documentEditor")
                    .delimiter
                    v-btn(icon @click="editor.chain().toggleBulletList().focus().run()" :class="{ active: editor.isActive('bulletList') }")
                        v-icon mdi-format-list-bulleted
                    v-btn(icon @click="editor.chain().toggleOrderedList().focus().run()" :class="{ active: editor.isActive('orderedList') }")
                        v-icon mdi-format-list-numbered
                    .delimiter


                v-menu(offset-y v-if="documentEditor")
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.dropdown(v-bind="attrs", v-on="on" title="Tables" plain :ripple="false")
                            v-icon mdi-table-large-plus
                            span.text Tables
                            v-icon.dropdown-icon mdi-menu-down
                    v-list
                        v-list-item(v-for="(item, index) in columns" :disabled="item.disabled" :key="index" @click="item.action")
                            v-list-item-icon
                                v-icon(v-text="item.icon")
                            v-list-item-title {{ item.name }}
                .delimiter
                v-menu(offset-y)
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.dropdown(v-bind="attrs", v-on="on" title="Add widget" plain :ripple="false")
                            v-icon mdi-shape-plus
                            span.text Widgets
                            v-icon.dropdown-icon mdi-menu-down
                    v-list
                        v-list-item(v-for="(widget, index) in widgets" v-if="widget.enabled" :key="index" @click="editor.chain().insertContent(`<${widget.tag} filler='writer'></${widget.tag}>`).focus().run()")
                            v-list-item-icon
                                v-icon(v-text="widget.icon")
                            v-list-item-title {{ widget.name }}

</template>

<script>
export default {
    props: ['editor'],


    methods:{
        addRow(){
            this.editor.chain().insertTable({ rows: 1, cols: 2, withHeaderRow: false }).focus().run();
        },
        setTextColor(color){
            let selectedColor = color;
            if(typeof color === 'object'){
                selectedColor = color.hex;
            }
            if(!this.settingColor && selectedColor){
                this.editor.chain().setColor(selectedColor).focus().run();
                this.colorDropdown = false;
            }
        },
        resetColor(){
            this.settingColor = true;
            this.editor.chain().unsetColor().focus().run();
            this.color = null;
            this.colorDropdown = false;
            this.$nextTick(() => this.settingColor = false)
        },
        setSelectedColor(){
            this.settingColor = true;
            this.color = this.selectedColor;
            this.$nextTick(() => this.settingColor = false)
        }
    },



    data(){
        return {
            color: '',
            colorDropdown: false,
            fontSizes: [8, 10, 12, 13, 14, 15, 17, 19, 21, 23, 25, 30],
            settingColor: false
        }
    },

    computed: {
        multiFormat(){
            return !this.editor.isActive('heading') && !this.editor.isActive('paragraph');
        },
        notAligned(){
          return !this.editor.isActive({ textAlign: 'left' }) && !this.editor.isActive({ textAlign: 'center' }) && !this.editor.isActive({ textAlign: 'right' }) && !this.editor.isActive({ textAlign: 'justify' });
        },
        selectedColor(){
            return this.editor.getAttributes('textStyle').color;
        },
        widgets(){
            return [
                {
                    name: 'Date',
                    tag: 'vue-date',
                    icon: 'mdi-calendar-range',
                    enabled: this.editor.name === 'document'
                },
                {
                    name: 'Name',
                    tag: 'vue-name',
                    icon: 'mdi-account-plus',
                    enabled: true
                },
                {
                    name: 'User name',
                    tag: 'vue-user-name',
                    icon: 'mdi-account-outline',
                    enabled: true
                },
                {
                    name: 'Email address',
                    tag: 'vue-email',
                    icon: 'mdi-at',
                    enabled: true
                },
                {
                    name: 'Signature',
                    tag: 'vue-signature',
                    editable: true,
                    icon: 'mdi-signature-freehand',
                    enabled: this.editor.name === 'document'
                },
                {
                    name: 'Ip Address',
                    tag: 'vue-ip',
                    icon: 'mdi-ip-network',
                    enabled: this.editor.name === 'document'
                },
                {
                    name: 'Text field',
                    tag: 'vue-input',
                    icon: 'mdi-format-text',
                    enabled: this.editor.name === 'document'
                },
                {
                    name: 'Image',
                    tag: 'vue-image',
                    icon: 'mdi-image',
                    enabled: this.editor.name === 'document'
                },
                {
                    name: 'Sign address',
                    tag: 'vue-sign-address',
                    icon: 'mdi-link',
                    enabled: true
                },
            ]
        },
        documentEditor() {
            return this.editor.name === 'document'
        },
        focusedEditor(){
            return this.$store.state.editor;
        },
        isTable(){
            return this.editor.isActive('table');
        },
        isRow(){
            return this.editor.isActive('table');
        },
        formats(){
            return [
                {
                    name: 'Align left',
                    action: () => this.editor.chain().setTextAlign('left').focus().run(),
                    active: this.editor.isActive({ textAlign: 'left' }),
                    icon: 'mdi-format-align-left'
                },
                {
                    name: 'Align center',
                    action: () => this.editor.chain().setTextAlign('center').focus().run(),
                    active: this.editor.isActive({ textAlign: 'center' }),
                    icon: 'mdi-format-align-center'
                },
                {
                    name: 'Align right',
                    action: () => this.editor.chain().setTextAlign('right').focus().run(),
                    active: this.editor.isActive({ textAlign: 'right' }),
                    icon: 'mdi-format-align-right'
                },
                {
                    name: 'Align justified',
                    action: () => this.editor.chain().setTextAlign('justify').focus().run(),
                    active: this.editor.isActive({ textAlign: 'justify' }),
                    icon: 'mdi-format-align-justify'
                },
            ]
        },
        columns(){
            return [
                {
                    name: 'Add table',
                    action: () => this.editor.chain().insertTable({ rows: 1, cols: 2, withHeaderRow: false }).focus().run(),
                    icon: 'mdi-table-large-plus'
                },
                {
                    name: 'Add row',
                    action: () => this.editor.chain().addRowAfter().focus().run(),
                    icon: 'mdi-table-row-plus-after',
                    disabled: !this.editor.can().addRowAfter()
                },
                {
                    name: 'Add column',
                    action: () => this.editor.chain().addColumnAfter().focus().run(),
                    icon: 'mdi-table-column-plus-after',
                    disabled: !this.editor.can().addColumnAfter()
                },
                {
                    name: 'Delete row',
                    action: () => this.editor.chain().deleteRow().focus().run(),
                    icon: 'mdi-table-row-remove',
                    disabled: !this.editor.can().deleteRow()
                },
                {
                    name: 'Delete column',
                    action: () => this.editor.chain().deleteColumn().focus().run(),
                    icon: 'mdi-table-column-remove',
                    disabled: !this.editor.can().deleteColumn()
                },
                {
                    name: 'Delete table',
                    action: () => this.editor.chain().deleteTable().focus().run(),
                    icon: 'mdi-table-large-remove',
                    disabled: !this.editor.can().deleteTable()
                },
            ]
        },
        tags() {
            const headingIds = [1, 2 ,3, 4, 5, 6];
            const headings = headingIds.map(id => ({
                name: `Heading ${id}`,
                action: () => this.editor.chain().toggleHeading({ level: id }).focus().run(),
                active: this.editor.isActive('heading', { level: id }),
                icon: `mdi-format-header-${id}`
            }));
            return [
                ...headings,
                {
                    name: 'Paragraph',
                    action: () => this.editor.chain().setParagraph().focus().run(),
                    active: this.editor.isActive('paragraph'),
                    icon: 'mdi-format-paragraph'
                },
            ]
        }
    },

    watch: {
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.editor-toolbar{
    //visibility: hidden;
    margin-left: -5px;
    margin-right: -5px;
    opacity: 0.1;
    pointer-events: none;
    padding-left: 15px;
    padding-right: 15px;

    &.focused{
        //visibility: visible;
        opacity: 1;
        pointer-events: initial;
    }
}

.v-btn.active{
    background-color: rgba(200, 200, 200, 0.5);
}

::v-deep .v-btn{
    margin-top: 3px;
    margin-bottom: 3px;
}








.v-btn.dropdown{
    box-shadow: none;
    padding: 0px;
    background-color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;

    .text{
        text-transform: none;
        color: $color-gray--75;

        padding-left: 5px;
        font-family: Sans-Serif;
    }

    .dropdown-icon{
        color: $color-gray--75;
    }
}

.v-list-item__icon{
    width: 50px;
    text-align: left;
}

.v-menu + .v-btn{
    margin-left: 5px;
}

.delimiter{
    height: 25px;
    display: inline-flex;

    border-left: 1px solid $color-gray--25;
    border-right: 1px solid $color-gray--25;
    margin-right: 10px;
    margin-left: 10px;
}

::v-deep{
    .v-list-item{
        cursor: pointer;

        &.active{
            background-color: rgba(200, 200, 200, 0.5);
        }
    }

    .v-color-picker__hue{
        background: linear-gradient(90deg,red,#ff0 16.66%,#0f0 33.33%,#0ff 50%,#00f 66.66%,#f0f 83.33%,red);
        margin-bottom: 0px !important;
    }

    .v-color-picker__dot{
        width: 25px;
        height: 25px;

        margin-right: 15px;
        display: none;
    }

    .v-color-picker__alpha{
        display: none;
    }

    .v-color-picker__controls {
        padding: 0px;
        margin-top: 13px;
    }

    .text-icon{
        margin-top: 8px;
    }

    .text-icon-holder{
        text-align: right;
    }
    .color-picker-holder{
        padding-left: 0px;
        padding-right: 0px;
    }
    .button-holder{
        text-align: right;
        margin-top: 5px;
    }
}

.col.buttons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.color-card{
    min-height: 75px;
    width: 350px;

    padding: 15px;

    label{
        width: 30%;
        display: inline-block;

        font-weight: bold;
    }

    ::v-deep{
        .v-select{
            width: 70%;
            display: inline-block;
        }

        .v-btn.reset-color{
            width: 100%;
            margin-top: 15px;
            background: #FFFFFF;
            box-shadow: none;
            border: 1px solid #AAAAAA;

            &.v-btn--disabled{
                background: #FFFFFF !important;
                opacity: 0.75;
                cursor: not-allowed;
            }
        }

        .v-color-picker{
            max-width: 100% !important;
        }

        .v-text-field{
            margin-top: 0px;
            padding-top: 0px;
        }
    }
}

@media screen and (min-width: 1900px) {

    .col.buttons{
        justify-content: center;
        align-items: center;
    }
}

</style>

<style lang="scss">

.v-menu__content{
    z-index: 500 !important;
}

.v-menu__content.align-menu{
    overflow: visible;
}
</style>
