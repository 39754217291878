<template lang="pug">
    .editor-page(v-if="editor")
        .editor(:class="{focused: editor.name === focusedEditor.name}")
            editor-content(:editor="editor")
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Extensions from './editor-extensions/Extensions.js';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Color from "@tiptap/extension-color";
import {nanoid} from "nanoid";


export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
        main: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            editor: null,
            person: null,
            elements: [
                {
                    name: 'Date',
                    tag: 'vue-date',
                    icon: 'mdi-calendar-range'
                },
                {
                    name: 'Name',
                    tag: 'vue-name',
                    icon: 'mdi-account-plus'
                },
                {
                    name: 'Signature',
                    tag: 'vue-signature',
                    editable: true,
                    icon: 'mdi-account-plus'
                },
            ],
        }
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            editorProps: {
                attributes: {
                    class: 'editor-holder',
                },
            },
            extensions: [
                StarterKit,
                Table,
                TableRow,
                TableHeader,
                TableCell,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                Color,

                ...Extensions
            ],
            onUpdate: () => {
                this.$emit('input', this.editor.getHTML())
                this.$emit('update:json', this.editor.getJSON())
                // this.$emit('input', this.editor.getJSON())
            },
            onFocus: () => this.setActive(),
        })

        this.editor.name = this.name;

        this.$emit('update:editor', this.editor);

        if(this.main){
            this.setActive();
        }

    },

    computed:{
        people(){
            return this.$store.getters.availableNames
        },
        focusedEditor(){
            return this.$store.state.editor;
        },
    },

    beforeDestroy() {
        this.editor.destroy()
    },

    methods:{
        setActive(){
            this.$store.commit('setActiveEditor', this.editor);
        }
    },

    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value
            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
}
</script>

<style lang="scss" scoped>

.editor-page{
    text-align: left;
}

::v-deep{

    .editor-toolbar{
        border: 1px solid #777777;
        background-color: #FFFFFF;
    }
}

.editor::v-deep{
    margin-top: 15px;

    //opacity: 0.5;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;

    &.focused{
        //opacity: 1;
        cursor: auto;
    }

    .ProseMirror{

    }

    .ProseMirror-focused{
        outline: none;
    }


    .editor-holder{
        padding: 1rem;
        height: 750px;
        overflow: visible;
        overflow-y: auto;
    }

    table{
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;

        tr{

            td{
                width: 50%;
                border: 1px dotted #AAAAAA;

                padding: 15px;

                vertical-align: top;

            }
        }
    }
}

</style>
