<template lang="pug">
  .document
      .editor-view(v-show="!preview")
          v-row.editors-row
              v-col.email-editor(sm="4")
                .email-editor-holder
                  h2 Email message
                  EditorToolbar(:editor="emailEditor")
                  v-text-field(placeholder="Email subject" solo @focus="resetEditor")
                  //h2.editor-title(v-if="!editingEmail") Email message
                  Editor(v-model="email" :json.sync="emailJSON" name="email" :editor.sync="emailEditor")

              v-col.document-editor(sm="8")
                  h2 Document editor
                  EditorToolbar(@preview="showPreview" :editor="documentEditor" @template="showTemplates" :template-editor="templateEditor")
                  //h2.editor-title(v-if="!editingDocument") Document editor
                  Editor(v-model="document" :main="true" :json.sync="documentJSON" name="document" :editor.sync="documentEditor")
                  v-dialog(light ref="templateDialog" content-class="template-dialog" v-model="templatePopup" persistent :width="500" :height="350")
                      v-card
                          v-card-title
                              slot(name="popup-title") Select template
                          v-divider
                          v-card-text
                            h5 Select template
                            v-row
                              v-col(sm="5")
                                .templates
                                  .template(v-for="template in templates" @click="selectTemplate(template)" :class="{selected: template.selected}") {{template.name}}
                              v-col(sm="7")
                                .template-preview(v-if="template")
                                  EditorPreview(:value="template.html" :hide-schedule-a="true")
                            h5 Save document as template
                            v-text-field(v-model="templateName")
                            v-btn(color="primary" dark :disabled="!templateName" @click="newTemplate") Save template
                          v-divider
                          v-card-actions
                              v-spacer
                                  v-btn(color="primary" text @click="useTemplate") Use template
                                  v-btn(text @click="dismiss") Dismiss


                  v-dialog(light ref="newTemplateDialog" content-class="new-template-dialog" v-model="newTemplatePopup" persistent :width="500" :height="350")
                      v-card
                          v-card-title
                              slot(name="popup-title") Save new template
                          v-divider
                          v-card-text
                              h5 Template name
                              v-text-field(v-model="templateName")
                          v-divider
                          v-card-actions
                              v-spacer
                                  v-btn(color="primary" text @click="newTemplate") Save
                                  v-btn(text @click="dismiss") Dismiss


              v-btn(@click="submit" v-if="!templateEditor") Submit
              v-btn(@click="submitTemplate" v-else) Save template
      .editor-preview(v-show="preview")
          v-row
            v-col

              .preview-toolbar
                v-btn.dropdown(@click="hidePreview")
                  v-icon mdi-file-document-edit-outline
                  | &nbsp;Back to editor

                v-btn.dropdown(@click="globalSchedule" :class="{selected: showsGlobalSchedule}" v-if="!templateEditor")
                  v-icon mdi-playlist-music-outline
                  | &nbsp;Global Schedule
                v-select.person-select(v-model="person", :items="people", item-text="name", label="Select person for preview", return-object, single-line, @input="updateSelected" solo v-if="!templateEditor")

          v-row
            v-col
              .preview-holder
                EditorPreview(:value="document" :hideScheduleA="templateEditor")

</template>

<script>
import api from '@/api';
import Editor from "@/components/Editor";
import EditorPreview from "../../components/EditorPreview";
import EditorToolbar from "../../components/EditorToolbar";

export default {
    components: {EditorToolbar, EditorPreview, Editor},
    data(){
        return {
            template: null,
            tab: 0,
            document: null,
            email: null,
            documentJSON: null,
            emailJSON: null,
            songs: [],
            preview: false,
            person: null,
            emailEditor: null,
            documentEditor: null,
            templatePopup: false,
            templates: [],
            templateName: null,
            newTemplatePopup: false,
        }
    },

    computed:{
        selectedSongUUIDs(){
            return this.$store.state.selectedSongUUIDs;
        },

        people(){
            return this.$store.getters.availableNames
        },
        editingEmail(){
            return this.$store.state.editor && this.$store.state.editor.name === 'email';
        },
        editingDocument(){
            return this.$store.state.editor && this.$store.state.editor.name === 'document';
        },
        showsGlobalSchedule(){
            return this.$store.state.globalSchedule;
        },
        templateEditor(){
            return this.$route.name === 'edit_template' || this.$route.name === 'new_template';
        },
        templateId(){
          let id = null;
          if(this.$route.name === 'edit_template'){
              id = this.$route.params.id;
          }
          return id;
        }
    },

    methods: {
        updateSelected(value){
            this.person = value;
            this.$store.commit('setGlobalSchedule', false);
            this.$store.commit('setSelectedPerson', value);
        },
        globalSchedule(){
            const show = !this.showsGlobalSchedule;
            this.resetSelect();
            if(show){
                this.$store.commit('setGlobalSchedule', true);
            }
        },
        selectTemplate(template){
          this.templates.forEach(template => template.selected = false);
          template.selected = true;
          this.template = template;
        },
        async submitTemplate(){
          if(this.templateId){
              await this.saveTemplate();
          }else{
              this.newTemplatePopup = true;
          }
        },
        async saveTemplate(){
            const template = await api.saveTemplate(
                this.templateId,
                {
                    name: this.templateName,
                    email: {
                        html: this.email,
                        json: this.emailJSON
                    },
                    document: {
                        html: this.document,
                        json: this.documentJSON
                    }
                }
            )

            return template;
        },
        async newTemplate(){
            const template = await api.newTemplate(
                {
                    name: this.templateName,
                    email: {
                        html: this.email,
                        json: this.emailJSON
                    },
                    document: {
                        html: this.document,
                        json: this.documentJSON
                    }
                }
            )
            this.$router.replace(`/template/${template.id}`);
            this.newTemplatePopup = false;
            //this.templates = await api.getTemplates();
        },
        showTemplates(){
            this.templatePopup = true;
        },
        showTemplatePopup(){
            this.newTemplatePopup = true;
        },
        useTemplate(){
          this.setTemplate(this.template);
          this.template.selected = false;
          this.template = null;
          this.templatePopup = false;
        },
        dismiss(){
          this.templatePopup = false;
          this.newTemplatePopup = false;
        },
        setTemplate(template){
            this.templateName = template.name;
            this.document = template.html;
            this.documentJSON = template.json;
            this.email = template.email;
            this.emailJSON = template.email_json;
        },
        showPreview(){
            this.preview = true
        },
        resetSelect(){
            this.updateSelected({})
            this.$store.commit('setGlobalSchedule', true);
        },
        hidePreview(){
            this.resetSelect();
            this.preview = false;
        },
        resetEditor(){
          this.$store.commit('setActiveEditor', {name: 'null'});
        },
        submit(){
            api.submitDocument({
                email: {
                    html: this.email,
                    json: this.emailJSON
                },
                document: {
                    html: this.document,
                    json: this.documentJSON
                },
                songs: this.selectedSongUUIDs
            });
        },
        async mount(){
            if(this.templateEditor){

                if(this.templateId){
                    const template = await api.getTemplate(this.templateId);
                    this.setTemplate(template);
                    return;
                }
            }
            if(this.selectedSongUUIDs){
                this.templates = await api.getTemplates();
                this.songs = await api.getFullSongs({
                    uuids: this.selectedSongUUIDs,
                });
                this.$store.commit('setSelectedSongs', this.songs.items);
            }else{
                this.$route.replace('dashboard');
            }
        }
    },
    beforeMount(){
        this.mount();
    },












    watch:{
        '$route': {
            handler: function(){
                this.mount();
            },
        },
    }




}
</script>

<style lang="scss" scoped>

.document{
}

.editor-preview{
    padding: 25px;
}

.preview-toolbar::v-deep{

    .v-select.person-select{
        display: inline-block;
        border: 1px solid #AAAAAA;
        transform: translateY(1px);

        &.v-text-field--solo .v-input__control{
            min-height: 34px;
        }

        .v-text-field__details{
            display: none;
        }

        .v-input__slot{
            margin-bottom: 0px;
            box-shadow: none;
        }
    }

    .v-btn.dropdown{
        background: #FFFFFF;
        box-shadow: none;
        padding: 5px;
        border: 1px solid #AAAAAA;
        display: inline-block;
        margin-right: 15px;

        &.selected{
            background: #0000FF;
            color: #FFFFFF;
        }
    }
}

.template-dialog{

    .template-preview{
        width: 255px;
        height: 300px;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.35);
        user-select: none;
        margin-top: 15px;



        .preview{
            width: 900px;
            height: 600px;

            overflow: visible;


            transform: translate(-315px, -205px) scale(0.28);
        }
    }

    .templates {
        border: 1px solid #EEEEEE;
        height: 300px;
        overflow: auto;

        margin-top: 15px;
        .template {
            padding: {
              top: 5px;
              bottom: 5px;
              left: 10px;
              right: 10px;
            };
            cursor: pointer;

            &.selected{
                background: #FFFF00;
            }
        }
    }

}

.document-editor::v-deep{
    .v-select{
        z-index: 1000;
    }
}

.preview-holder{
    border-radius: 5px;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.35);
    min-height: 750px;

    ::v-deep & >.preview{
        background: #FFFFFF;
        padding: 15px;

        min-height: 750px;
    }
}

.editors-row{
    margin-bottom: 0px;
    margin-top: 25px;

    position: relative;

    &.editors{

        .col{
        }
    }


    .email-editor{

        .email-editor-holder{
            padding: 25px;
            border-radius: 15px;
            background-color: #AAAAFF;
            box-shadow: 0px 0px 3px 1px rgba(0,0,125,0.5);
        }
    }


    h2.editor-title{
        margin-top: 0px;
        margin-bottom: px;
        position: absolute;
        top: 21px;
    }

    h2{
        margin-bottom: 15px;
        color: #5555FF
    }

    ::v-deep{
        .editor-toolbar{
            margin-top: 0px;
        }

        .v-text-field{
            margin-top: 15px;

            .v-input__slot{
                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.35);
            }

            .v-text-field__details{
                display: none;
            }

            &.v-input--is-focused{

                .v-input__slot{
                    box-shadow: 0px 3px 5px 3px rgba(0,0,0,0.35);
                }
            }

        }
    }

    .col{
        padding: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
</style>
